function App() {
  const Level1 = () => <span className="font-bold text-green-300">ok</span>;
  const Level2 = () => <span className="font-bold text-green-500">good</span>;
  const Level3 = () => <span className="font-bold text-green-700">best</span>;

  const Item = ({ title, efficacy, children, references }) => {
    return (
      <div className="border rounded p-3">
        <div className="flex mb-3">
          <div className="text-gray-700 text-xl font-bold grow">{title}</div>
          <div className="inline-block">
            {efficacy === 1 && <Level1 />}
            {efficacy === 2 && <Level2 />}
            {efficacy === 3 && <Level3 />}
          </div>
        </div>
        <div className="text-gray-700 max-w-lg mb-3">{children}</div>
        {references && (
          <ul className="list-disc ml-4">
            {references.map((reference) => (
              <li className="">
                <a
                  className="text-gray-700 break-all underline"
                  href={reference}
                  target="_blank"
                  rel="noreferrer"
                >
                  {reference}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  const items = [
    {
      title: "Exercise",
      body: "Exercise can be a massive help. I do 30 minutes on an indoor trampoline whenever I'm having a panic attack, and it completely removes all anxiety. ",
      efficacy: 3,
      references: [
        "https://www.health.harvard.edu/blog/can-exercise-help-treat-anxiety-2019102418096",
      ],
    },
    {
      title: "Propanolol",
      body: "A beta-blocker which works extremely well to get rid of anxiety. I found that it made me too tired in the day, and interfered with my exercise routine. For this reason I don't use it anymore, but some people really like it. You will need to speak to your doctor to get a prescription.",
      efficacy: 3,
      references: ["https://www.nhs.uk/conditions/beta-blockers/"],
    },
    {
      title: "Medical Cannabis",
      body: "Available in the UK with a private prescription. Works extremely well for anxiety, but I recommend avoiding cannabis with a small amount of CBD in it. Go for something that has a good mix of CBD/THC.",
      efficacy: 3,
      references: ["https://www.drugscience.org.uk/twenty21/"],
    },
    {
      title: "Vagus Nerve Massage",
      body: "This technique has been extremely helpful for me. I'm not sure if it's a placebo effect, but I love it. Some people say that it doesn't work for them.",
      efficacy: 3,
      references: ["https://youtu.be/LnV3Q2xIb1U"],
    },
    {
      title: "Voice Journaling",
      body: "A faster and easier way to keep a daily journal is to just use a voice recorder and talk to it every morning. I have found this really helpful to keep me grounded in the moment.",
      efficacy: 3,
      references: [],
    },
    {
      title: "L-Theanine",
      body: "My favourite thing for anxiety. It really  helps, and is pretty safe. Speak to your Doctor about taking it (unlikely that that even know what it is). It's available on Amazon without a prescription in the UK.",
      efficacy: 3,
      references: [
        "https://en.wikipedia.org/wiki/Theanine",
        "https://www.amazon.co.uk/L-Theanine-50g-Focus-Stress-Anxiety/dp/B01DSZH2AO",
      ],
    },
    {
      title: "POWERbreathe",
      body: "I use a POWERbreathe every morning and evening. I think this is helping a lot, but I don't have any evidence. Definitely worth a try!",
      efficacy: 2,
      references: ["https://www.powerbreathe.com/"],
    },
    {
      title: "Cold Showers",
      body: "This has been helpful for me, but it's hard to quantify. Getting into cold water has made me more likely to want to jump in the sea, and it has made me more active. So it might help indirectly. There also appears to be evidence that it can directly help with anxiety.",
      efficacy: 2,
      references: ["https://www.wimhofmethod.com/"],
    },
    {
      title: "Journaling",
      body: "Writing down your thoughts every day can help you notice your fears/anxieties, which in turn helps you deal with them. I found this to be too much of a time sink, so I gave it up.",
      efficacy: 2,
      references: [],
    },
    {
      title: "Drawing",
      body: "",
      efficacy: 1,
      references: ["Being creative helps me calm down sometimes."],
    },
    {
      title: "Vagus Nerve Reset",
      body: "This hasn't worked for me yet, but a lot of people seem to like it.",
      efficacy: 1,
      references: ["https://www.youtube.com/watch?v=eFV0FfMc_uo"],
    },
  ];

  return (
    <div className="container mx-auto px-5 pb-10">
      <h1 className="text-gray-700 text-3xl mb-5 mt-10 font-bold">Anxiety</h1>
      <div className="text-gray-700 text-md">
        I have General Anxiety Disorder, and these are a list of things I have
        tried.
      </div>
      <div className="mb-3 text-gray-700 text-md">
        Items are rated with <Level1 />, <Level2 /> or <Level3 /> for how well
        they worked for me personally.
      </div>
      <div className="mb-5 text-green-700 text-md border border-green-500 bg-green-50 p-3 rounded">
        NOTE: I am not a doctor. See a doctor/therapist before you listen to me.
      </div>
      <div className="space-y-5 mb-10">
        {items.map((item) => (
          <Item {...item}>{item.body}</Item>
        ))}
      </div>
      <div className="text-md text-gray-700 font-bold text-center">
        Created by{" "}
        <a className="underline" href="https://andrewvos.com">
          Andrew Vos
        </a>{" "}
        -{" "}
        <a className="underline" href="mailto:andrew@andrewvos.com">
          Contribute to this list
        </a>
      </div>
    </div>
  );
}

export default App;
